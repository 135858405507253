import "core-js/modules/web.timers.js";
import Layout3 from '@/components/layouts/Layout3.vue';
import SiteHeader from '@/components/SiteHeader.vue';
import { useWidgetStore } from '@/stores/widget';
export default defineComponent({
  components: {
    SiteHeader: SiteHeader,
    Layout3: Layout3
  },
  setup: function setup() {
    var _useWidgetStore = useWidgetStore(),
      resetWidgets = _useWidgetStore.resetWidgets;
    onBeforeMount(resetWidgets);
    var _useNuxtApp = useNuxtApp(),
      $config = _useNuxtApp.$config;
    var domainName = computed(function () {
      return $config.public.domainName;
    });
    return {
      domainName: domainName
    };
  },
  head: function head() {
    return {
      title: 'Maintenance'
    };
  },
  created: function created() {
    // Reload page every 5 minutes to automatically send user to / when
    // maintenance ends.
    setTimeout(function () {
      window.location.reload();
    }, 5 * 60 * 1000);
  }
});